import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Container, Row, Col } from "react-bootstrap"
import Containers from "../components/container"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import blogStyles from "../styles/blog.module.css"
import SEO from "../components/seo"


export default function BlogPost({ data }) {
  let post = data.markdownRemark
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
    return (
      <Containers location={post.frontmatter} title={post.frontmatter.title}>
        <SEO 
           title={post.frontmatter.title}
           description={post.frontmatter.description}
           image={featuredImgFluid}
           pathname={post.frontmatter}
           />
      <Header />
        <Container fluid="xl">
          <Row className={blogStyles.blogpostbreadcrumbs}>
            <Col>
                <p className={blogStyles.pbreadcrumbsize}>
                  <a href="/"><span className={blogStyles.pbreadcrumbs}>Home</span></a> &#187;
                  <a href="/blog/"><span className={blogStyles.pbreadcrumbs}>บทความ</span></a> &#187;
                  <span className={blogStyles.pbreadcrumbsblog}>{post.frontmatter.title}</span>
                </p>
            </Col>
          </Row>
          <Row className={blogStyles.blogpostcontainer}>
            <Col md={3}></Col>
            <Col md={6} className={blogStyles.blogcontent}>
            <h1 className={blogStyles.h1blogpost}>{post.frontmatter.title}</h1>
            <Img fluid={featuredImgFluid} className={blogStyles.imagep} />
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </Col>
            <Col md={3}></Col>
          </Row>
        </Container>
      <Footer />
      </Containers>
    )
}

export const query = graphql`
    query($slug: String!) {
      site {
        siteMetadata {
          title
          author
        }
      }
      markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        frontmatter {
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
`